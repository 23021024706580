<template>
    <section class="timing-restaurant" v-if="status === 'success'">
        <h2 class="sr-only">Данные отчета "Тайминг"</h2>
        <div class="timing-restaurant__averate-time" v-if="modeId === 'averageTime'">
            <div class="timing-restaurant__box box">
                <table class="timing-restaurant__table timing-restaurant__table_striped timing-restaurant__table_hover">
                    <colgroup>
                        <col class="timing-restaurant__table-column timing-restaurant__table-column_title">
                        <col class="timing-restaurant__table-column timing-restaurant__table-column_cooking-duration">
                        <col class="timing-restaurant__table-column timing-restaurant__table-column_stand-duration">
                        <col class="timing-restaurant__table-column timing-restaurant__table-column_waiting-duration">
                        <col class="timing-restaurant__table-column timing-restaurant__table-column_delivery-duration">
                        <col class="timing-restaurant__table-column timing-restaurant__table-column_total-duration">
                        <col class="timing-restaurant__table-column timing-restaurant__table-column_return-duration">
                    </colgroup>
                    <thead>
                        <tr class="timing-restaurant__table-row timing-restaurant__table-row_head">
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_sortable"
                                scope="col"
                                @click="setSorting('averageTime', 'title')"
                            >
                                <span class="timing-restaurant__table-value">Источник</span>
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'title' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'title' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_sortable"
                                scope="col"
                                @click="setSorting('averageTime', 'cooking_duration')"
                            >
                                <span class="timing-restaurant__table-value">На кухне</span>
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'cooking_duration' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'cooking_duration' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_sortable"
                                scope="col"
                                @click="setSorting('averageTime', 'stand_duration')"
                            >
                                <span class="timing-restaurant__table-value">В отстойнике</span>
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'stand_duration' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'stand_duration' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_sortable"
                                scope="col"
                                @click="setSorting('averageTime', 'waiting_duration')"
                            >
                                <span class="timing-restaurant__table-value">До отправки</span>
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'waiting_duration' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'waiting_duration' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_sortable"
                                scope="col"
                                @click="setSorting('averageTime', 'delivery_duration')"
                            >
                                <span class="timing-restaurant__table-value">В пути</span>
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'delivery_duration' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'delivery_duration' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_sortable"
                                scope="col"
                                @click="setSorting('averageTime', 'total_duration')"
                            >
                                <span class="timing-restaurant__table-value">Общее</span>
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'total_duration' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'total_duration' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_sortable"
                                scope="col"
                                @click="setSorting('averageTime', 'return_duration')"
                            >
                                <span class="timing-restaurant__table-value">Обратно</span>
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'return_duration' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'return_duration' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="timing-restaurant__table-row timing-restaurant__table-row_body timing-restaurant__table-row_pinned">
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_title">Норма</td>
                            <td class="timing-restaurant__table-cell">
                                {{standard.cooking_duration | durationFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell">
                                {{standard.stand_duration | durationFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell">
                                {{standard.waiting_duration | durationFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell">
                                {{standard.delivery_duration | durationFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell">
                                {{standard.total_duration | durationFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell">
                                {{standard.return_duration | durationFormat}}
                            </td>
                        </tr>
                        <tr class="timing-restaurant__table-row timing-restaurant__table-row_body timing-restaurant__table-row_pinned"
                            v-if="couriers.length > 1"
                        >
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_title">Итого</td>
                            <td class="timing-restaurant__table-cell">
                                <span class="timing-restaurant__table-value">{{total.cooking_duration | durationFormat}}</span>
                                <difference class="timing-restaurant__table-value"
                                    :primaryValue="total.cooking_duration"
                                    :compareValue="standard.cooking_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="timing-restaurant__table-cell">
                                <span class="timing-restaurant__table-value">{{total.stand_duration | durationFormat}}</span>
                                <difference class="timing-restaurant__table-value"
                                    :primaryValue="total.stand_duration"
                                    :compareValue="standard.stand_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="timing-restaurant__table-cell">
                                <span class="timing-restaurant__table-value">{{total.waiting_duration | durationFormat}}</span>
                                <difference class="timing-restaurant__table-value"
                                    :primaryValue="total.waiting_duration"
                                    :compareValue="standard.waiting_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="timing-restaurant__table-cell">
                                <span class="timing-restaurant__table-value">{{total.delivery_duration | durationFormat}}</span>
                                <difference class="timing-restaurant__table-value"
                                    :primaryValue="total.delivery_duration"
                                    :compareValue="standard.delivery_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="timing-restaurant__table-cell">
                                <span class="timing-restaurant__table-value">{{total.total_duration | durationFormat}}</span>
                                <difference class="timing-restaurant__table-value"
                                    :primaryValue="total.total_duration"
                                    :compareValue="standard.total_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="timing-restaurant__table-cell">
                                <span class="timing-restaurant__table-value">{{total.return_duration | durationFormat}}</span>
                                <difference class="timing-restaurant__table-value"
                                    :primaryValue="total.return_duration"
                                    :compareValue="standard.return_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                        </tr>
                        <tr class="timing-restaurant__table-row timing-restaurant__table-row_body"
                            v-for="courier in couriers"
                            :key="courier.id"
                        >
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_title">
                                {{courier.title}}
                            </td>
                            <td class="timing-restaurant__table-cell">
                                <span class="timing-restaurant__table-value">{{courier.cooking_duration | durationFormat}}</span>
                                <difference class="timing-restaurant__table-value"
                                    :primaryValue="courier.cooking_duration"
                                    :compareValue="standard.cooking_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="timing-restaurant__table-cell">
                                <span class="timing-restaurant__table-value">{{courier.stand_duration | durationFormat}}</span>
                                <difference class="timing-restaurant__table-value"
                                    :primaryValue="courier.stand_duration"
                                    :compareValue="standard.stand_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="timing-restaurant__table-cell">
                                <span class="timing-restaurant__table-value">{{courier.waiting_duration | durationFormat}}</span>
                                <difference class="timing-restaurant__table-value"
                                    :primaryValue="courier.waiting_duration"
                                    :compareValue="standard.waiting_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="timing-restaurant__table-cell">
                                <span class="timing-restaurant__table-value">{{courier.delivery_duration | durationFormat}}</span>
                                <difference class="timing-restaurant__table-value"
                                    :primaryValue="courier.delivery_duration"
                                    :compareValue="standard.delivery_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="timing-restaurant__table-cell">
                                <span class="timing-restaurant__table-value">{{courier.total_duration | durationFormat}}</span>
                                <difference class="timing-restaurant__table-value"
                                    :primaryValue="courier.total_duration"
                                    :compareValue="standard.total_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="timing-restaurant__table-cell">
                                <span class="timing-restaurant__table-value">{{courier.return_duration | durationFormat}}</span>
                                <difference class="timing-restaurant__table-value"
                                    :primaryValue="courier.return_duration"
                                    :compareValue="standard.return_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="timing-restaurant__late-data" v-else-if="modeId === 'lateData'">
            <div class="timing-restaurant__box box">
                <table class="timing-restaurant__table timing-restaurant__table_striped timing-restaurant__table_hover">
                    <colgroup>
                        <col class="timing-restaurant__table-column timing-restaurant__table-column_late-title">
                        <col class="timing-restaurant__table-column timing-restaurant__table-column_late-value">
                        <col class="timing-restaurant__table-column timing-restaurant__table-column_late-value">
                        <col class="timing-restaurant__table-column timing-restaurant__table-column_late-value">
                        <col class="timing-restaurant__table-column timing-restaurant__table-column_late-value">
                        <col class="timing-restaurant__table-column timing-restaurant__table-column_late-value">
                        <col class="timing-restaurant__table-column timing-restaurant__table-column_late-value">
                        <col class="timing-restaurant__table-column timing-restaurant__table-column_late-value">
                        <col class="timing-restaurant__table-column timing-restaurant__table-column_late-value">
                        <col class="timing-restaurant__table-column timing-restaurant__table-column_late-value">
                        <col class="timing-restaurant__table-column timing-restaurant__table-column_late-value">
                        <col class="timing-restaurant__table-column timing-restaurant__table-column_late-value">
                        <col class="timing-restaurant__table-column timing-restaurant__table-column_late-value">
                    </colgroup>
                    <thead>
                        <tr class="timing-restaurant__table-row timing-restaurant__table-row_head">
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_baseline timing-restaurant__table-cell_head timing-restaurant__table-cell_sortable"
                                scope="col"
                                rowspan="2"
                                @click="setSorting('lateData', 'title')"
                            >
                                <span class="timing-restaurant__table-value">Источник</span>
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'title' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'title' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_right" scope="col" colspan="2">На кухне</th>
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_right" scope="col" colspan="2">В отстойнике</th>
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_right" scope="col" colspan="2">До отправки</th>
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_right" scope="col" colspan="2">В пути</th>
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_right" scope="col" colspan="2">Всего</th>
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_right" scope="col" colspan="2">Обратно</th>
                        </tr>
                        <tr class="timing-restaurant__table-row timing-restaurant__table-row_head">
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_sortable timing-restaurant__table-cell_right timing-restaurant__table-cell_small timing-restaurant__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'cooking_late')"
                            >
                                <span class="timing-restaurant__table-value">∑</span>
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'cooking_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'cooking_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_sortable timing-restaurant__table-cell_right timing-restaurant__table-cell_small timing-restaurant__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'cooking_percent_late')"
                            >
                                <span class="timing-restaurant__table-value">%</span>
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'cooking_percent_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'cooking_percent_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_sortable timing-restaurant__table-cell_right timing-restaurant__table-cell_small timing-restaurant__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'stand_late')"
                            >
                                <span class="timing-restaurant__table-value">∑</span>
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'stand_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'stand_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_sortable timing-restaurant__table-cell_right timing-restaurant__table-cell_small timing-restaurant__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'stand_percent_late')"
                            >
                                <span class="timing-restaurant__table-value">%</span>
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'stand_percent_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'stand_percent_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_sortable timing-restaurant__table-cell_right timing-restaurant__table-cell_small timing-restaurant__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'waiting_late')"
                            >
                                <span class="timing-restaurant__table-value">∑</span>
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'waiting_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'waiting_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_sortable timing-restaurant__table-cell_right timing-restaurant__table-cell_small timing-restaurant__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'waiting_percent_late')"
                            >
                                <span class="timing-restaurant__table-value">%</span>
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'waiting_percent_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'waiting_percent_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_sortable timing-restaurant__table-cell_right timing-restaurant__table-cell_small timing-restaurant__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'delivery_late')"
                            >
                                <span class="timing-restaurant__table-value">∑</span>
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'delivery_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'delivery_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_sortable timing-restaurant__table-cell_right timing-restaurant__table-cell_small timing-restaurant__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'delivery_percent_late')"
                            >
                                <span class="timing-restaurant__table-value">%</span>
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'delivery_percent_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'delivery_percent_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_sortable timing-restaurant__table-cell_right timing-restaurant__table-cell_small timing-restaurant__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'total_late')"
                            >
                                <span class="timing-restaurant__table-value">∑</span>
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'total_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'total_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_sortable timing-restaurant__table-cell_right timing-restaurant__table-cell_small timing-restaurant__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'total_percent_late')"
                            >
                                <span class="timing-restaurant__table-value">%</span>
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'total_percent_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'total_percent_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_sortable timing-restaurant__table-cell_right timing-restaurant__table-cell_small timing-restaurant__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'return_late')"
                            >
                                <span class="timing-restaurant__table-value">∑</span>
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'return_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'return_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-restaurant__table-cell timing-restaurant__table-cell_head timing-restaurant__table-cell_sortable timing-restaurant__table-cell_right timing-restaurant__table-cell_small timing-restaurant__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'return_percent_late')"
                            >
                                <span class="timing-restaurant__table-value">%</span>
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'return_percent_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-restaurant__table-value timing-restaurant__table-value_icon"
                                    v-if="sortings[modeId].field === 'return_percent_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="timing-restaurant__table-row timing-restaurant__table-row_body timing-restaurant__table-row_pinned"
                            v-if="couriers.length > 1"
                        >
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_title">Итого</td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{restaurantTotal.cooking_late | intFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{restaurantTotal.cooking_percent_late | percentFloatFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{restaurantTotal.stand_late | intFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{restaurantTotal.stand_percent_late | percentFloatFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{restaurantTotal.waiting_late | intFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{restaurantTotal.waiting_percent_late | percentFloatFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{restaurantTotal.delivery_late | intFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{restaurantTotal.delivery_percent_late | percentFloatFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{restaurantTotal.total_late | intFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{restaurantTotal.total_percent_late | percentFloatFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{restaurantTotal.return_late | intFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{restaurantTotal.return_percent_late | percentFloatFormat}}
                            </td>
                        </tr>
                        <tr class="timing-restaurant__table-row timing-restaurant__table-row_body"
                            v-for="courier in couriers"
                            :key="courier.id"
                        >
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_title">
                                {{courier.title}}
                            </td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{courier.cooking_late | intFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{courier.cooking_percent_late | percentFloatFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{courier.stand_late | intFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{courier.stand_percent_late | percentFloatFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{courier.waiting_late | intFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{courier.waiting_percent_late | percentFloatFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{courier.delivery_late | intFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{courier.delivery_percent_late | percentFloatFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{courier.total_late | intFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{courier.total_percent_late | percentFloatFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{courier.return_late | intFormat}}
                            </td>
                            <td class="timing-restaurant__table-cell timing-restaurant__table-cell_small timing-restaurant__table-cell_right">
                                {{courier.return_percent_late | percentFloatFormat}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="timing-restaurant__late-distribution" v-else-if="modeId === 'lateDistribution'">
            <figure class="timing-restaurant__box box">
                <figcaption class="timing-restaurant__figcaption h2">{{restaurant.title}}</figcaption>
                <highcharts :options="hoursChartOptions" />
            </figure>
        </div>
        <div class="timing-total__late-distribution" v-else-if="modeId === 'deliveryTimeDistribution'">
            <div class="timing-total__box box">
                <highcharts class="timing-total__chart" :options="deliveryTimeDistributionChartOptions" :key="'deliveryTimeDistributionChart'" />
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState, mapMutations } from "vuex";
    import { compareStrings, compareNumbers } from "@/helpers/compare";
    import Difference from "@/components/Difference";

    export default {
        name: "TimingRestaurant",
        props: {
            id: {
                type: String,
                required: true
            },
            status: {
                type: String,
                required: true
            },
            modeId: {
                type: String,
                required: true
            },
            isReportTotalLinkVisible: {
                type: Boolean,
                required: true
            },
            showLateDistributionSelect: {
                type: Boolean,
                required: true
            },
            getHoursChartOptions: {
                type: Function,
                required: true
            },
            reportTiming: {
                required: true
            },
            standard: {
                required: true
            },
            total: {
                required: true
            },
            userRestaurants: {
                required: true
            }
        },
        components: {
            Difference
        },
        data() {
            return {
                sortings: {
                    averageTime: {
                        field: "total_duration",
                        direction: "ASC"
                    },
                    lateData: {
                        field: "total_percent_late",
                        direction: "ASC"
                    }
                },
            };
        },
        computed: {
            ...mapState({
                title: state => state.page.title,
            }),
            restaurant() {
                return this.reportTiming?.restaurants?.find(({ id }) => id === this.id);
            },
            restaurantTotal() {
                return this.restaurant?.total;
            },
            couriers() {
                return this.restaurant?.couriers?.sort((courier1, courier2) => {
                    if (this.sortings[this.modeId].field === "title") {
                        return compareStrings(
                            courier1.title,
                            courier2.title,
                            this.sortings[this.modeId].direction
                        );
                    }

                    return compareNumbers(
                        courier1[this.sortings[this.modeId].field],
                        courier2[this.sortings[this.modeId].field],
                        this.sortings[this.modeId].direction
                    );
                });
            },
            hours() {
                return this.restaurant?.hours;
            },
            restaurantsDeliveryTimeDistrubution() {
                return this.restaurant?.timeframe;
            },
            hoursChartOptions() {
                return this.getHoursChartOptions(this.hours);
            },
            deliveryTimeDistributionChartOptions() {
                return {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: "pie"
                    },
                    title: {
                        text: null
                    },
                    tooltip: {
                        pointFormat: '{point.percentage:.1f}% ({point.y} из {point.total})'
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '{point.name}: {point.percentage:.1f} %'
                            }
                        }
                    },
                    series: [{
                        data: [...this.restaurantsDeliveryTimeDistrubution?.map(({ title: name, count: y })  => ({ name, y}))]
                    }]
                };
            }
        },
        methods: {
            ...mapMutations([
                "setTitle",
                "toggleReportTotalLink",
                "setTimingParameter",
            ]),
            setSorting(tableId, field) {
                if (this.sortings[tableId].field === field) {
                    this.sortings[tableId].direction = this.sortings[tableId].direction === "ASC" ? "DESC" : "ASC";
                } else {
                    this.sortings[tableId].field = field
                }
            }
        },
        created() {
            if (!this.userRestaurants?.ids?.includes(this.id)) {
                this.$router.push({
                    name: "Error401",
                    params: {
                        requestedResource: `Ресторан ${this.id}`
                    }
                });
            } else {
                let restaurantTitle = this.userRestaurants?.byId?.[this.id]?.title;

                if (restaurantTitle && !this.title.includes(restaurantTitle)) {
                    this.setTitle(`${this.title} "${restaurantTitle}"`);
                }

                if (this.userRestaurants?.ids?.length > 1 && !this.isReportTotalLinkVisible) {
                    this.toggleReportTotalLink(true);
                }

                if (this.showLateDistributionSelect) {
                    this.setTimingParameter({ parameter: "showLateDistributionSelect", value: false });
                }
            }
        }
    }
</script>

<style lang="scss">
    .timing-restaurant__box {
        padding: 16px;
        overflow-x: auto;

        & + & {
            margin-top: 15px;
        }
    }
    .timing-restaurant__table {
        width: 100%;
    }
    .timing-restaurant__table-caption {
        padding: 8px;
        font-size: 24px;
        font-weight: bold;
    }
    .timing-restaurant__table-column {
        &_title {
            width: 25%;
        }
        &_cooking-duration {
            width: 12.5%;
        }
        &_stand-duration {
            width: 12.5%;
        }
        &_waiting-duration {
            width: 12.5%;
        }
        &_delivery-duration {
            width: 12.5%;
        }
        &_total-duration {
            width: 12.5%;
        }
        &_return-duration {
            width: 12.5%;
        }
        &_late-title {
            width: 20%;
        }
        &_late-value {
            width: calc(80% / 12);
        }
    }
    .timing-restaurant__table-row {
        &_body {
            .timing-restaurant__table_striped &:nth-of-type(odd) {
                background-color: $background-gray-primary;
            }

            .timing-restaurant__table_hover &:hover {
                background-color: $gray-form;
            }
        }

        &_pinned {
            background-color: $gray-form !important;
        }
    }
    .timing-restaurant__table-cell {
        font-size: 20px;
        padding: 16px 8px;

        &_low {
            padding-top: 8px;
            padding-bottom: 8px;
        }

        &_head {
            padding: 8px;
            white-space: nowrap;
        }

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
        }

        &_centered {
            text-align: center;
        }

        &_right {
            text-align: right;
        }

        &_small {
            font-size: 16px;
        }

        &_baseline {
            vertical-align: baseline;
        }
    }
    .timing-restaurant__table-value {
        line-height: 1;
        vertical-align: middle;

        & + & {
            margin-left: 5px;
        }

        &_icon {
            width: 16px;
            height: 16px;
        }

        &_positive {
            color: green;
        }

        &_negative {
            color: red;
        }
    }
</style>
